import React from 'react';
import './aboutitems.css';

const AboutItems = () => {
  return (
    <div className="about-items">
      <div className="about-items__item about-items__item-first">
        <p className="about-items__text">10 лет успешного опыта</p>
      </div>

      <div className="about-items__item about-items__item-second">
        <p className="about-items__text">Проекты для крупных инжинирнгованых компаний</p>
      </div>

      <div className="about-items__item about-items__item-third">
        <p className="about-items__text">более 20 реализованых проетков</p>
      </div>
    </div>
  );
};

export default AboutItems;
