import React from 'react';
import '../css/notfound.css';

import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Layout from '../components/Layout';

import robot from '../assets/not-found/robot.png';

const NotFound = () => {
  window.scroll(0, 0);
  return (
    <div className="not-found">
      <Layout>
        <Header />
        <div className="not-found__content">
          <h2 className="not-found__title">ошибка 404</h2>
          <p className="not-found__text">
            Кажется что-то пошло не так! Страница отсутствует либо просто не работает. Перейдите на
            нашу
            <Link className="not-found__link" to="/">
              главную страницу
            </Link>
            <br />и попробуйте найти необходимую информацию там.
          </p>
        </div>
      </Layout>
      <img src={robot} alt="Изображение руки робота" className="not-found__robot" />
      <Footer />
    </div>
  );
};

export default NotFound;
