import React from 'react';
import './businesssolutionblock.css';

import first from '../../assets/services/businesssolution/first.svg';
import second from '../../assets/services/businesssolution/second.svg';
import third from '../../assets/services/businesssolution/third.svg';
import fourth from '../../assets/services/businesssolution/fourth.svg';
import fifth from '../../assets/services/businesssolution/fifth.svg';
import sixth from '../../assets/services/businesssolution/sixth.svg';
import seventh from '../../assets/services/businesssolution/seventh.svg';
import eighth from '../../assets/services/businesssolution/eighth.svg';
import ninth from '../../assets/services/businesssolution/ninth.svg';
import tenth from '../../assets/services/businesssolution/tenth.svg';

const BusinessSolutionBlock = () => {
  return (
    <div className="business-solution-block">
      <h3 className="business-solution-block__title">
        Цифровые технологии – это действенный инструмент для улучшения различных сфер
      </h3>
      <div className="business-solution-block__content">
        <div className="business-solution-block__item">
          <div className="business-solution-block__icon">
            <img src={first} alt="Декоративный элемент" />
          </div>
          <p className="business-solution-block__text">
            Администрирование, настройка серверов, серверов 1С и ERP, сетевых хранилищ, баз данных,
            подбор конфигурации сервера под ваши нужды
          </p>
        </div>
        <div className="business-solution-block__item">
          <div className="business-solution-block__icon">
            <img src={second} alt="Декоративный элемент" />
          </div>
          <p className="business-solution-block__text">
            Настройка сетевого оборудования (маршрутизаторы, управляемые коммутаторы, точки доступа
            Wi-Fi)
          </p>
        </div>
        <div className="business-solution-block__item">
          <div className="business-solution-block__icon">
            <img src={third} alt="Декоративный элемент" />
          </div>
          <p className="business-solution-block__text">
            Подбор, настройка и администрирование почтового сервера, создание, настройка
            корпоративной почты
          </p>
        </div>
        <div className="business-solution-block__item">
          <div className="business-solution-block__icon">
            <img src={fourth} alt="Декоративный элемент" />
          </div>
          <p className="business-solution-block__text">
            Виртуализация серверов, мониторинг серверных ОС, обеспечение бесперебойной работы и
            информационной безопасности
          </p>
        </div>
        <div className="business-solution-block__item">
          <div className="business-solution-block__icon">
            <img src={fifth} alt="Декоративный элемент" />
          </div>
          <p className="business-solution-block__text">
            Установка, настройка систем сдачи отчетности через Интернет, CRM-систем, доступов на
            электронные площадки
          </p>
        </div>
        <div className="business-solution-block__item">
          <div className="business-solution-block__icon">
            <img src={sixth} alt="Декоративный элемент" />
          </div>
          <p className="business-solution-block__text">
            Установка, настройка программного обеспечения
          </p>
        </div>
        <div className="business-solution-block__item">
          <div className="business-solution-block__icon">
            <img src={seventh} alt="Декоративный элемент" />
          </div>
          <p className="business-solution-block__text">Настройка VPN туннелей, IPSec</p>
        </div>
        <div className="business-solution-block__item">
          <div className="business-solution-block__icon">
            <img src={eighth} alt="Декоративный элемент" />
          </div>
          <p className="business-solution-block__text">
            Оптимизация, обновление, настройка операционных систем (Windows, Linux)
          </p>
        </div>
        <div className="business-solution-block__item">
          <div className="business-solution-block__icon">
            <img src={ninth} alt="Декоративный элемент" />
          </div>
          <p className="business-solution-block__text">
            Настройка офисного периферийного оборудования (принтеры, плоттеры, МФУ, сканеры)
          </p>
        </div>
        <div className="business-solution-block__item">
          <div className="business-solution-block__icon">
            <img src={tenth} alt="Декоративный элемент" />
          </div>
          <p className="business-solution-block__text">Анализ состояния компьютерного парка</p>
        </div>
      </div>
    </div>
  );
};

export default BusinessSolutionBlock;
