import React from 'react';
import './services.css';

import { Link } from 'react-router-dom';

const Services = () => {
  return (
    <div className="services" id="services">
      <h2 className="title-block">УСЛУГИ</h2>
      <div className="services__content">
        <Link className="services__item services__first" to="/outstaffing">
          <h3 className="services__subtitle">ИТ-АУТСТАФФИНГ</h3>
        </Link>

        <Link className="services__item services__second" to="/outsourcing">
          <h3 className="services__subtitle">ИТ-Аутсорсинг</h3>
        </Link>

        <Link className="services__item services__third" to="/virtual-desktop-infrastructure">
          <h3 className="services__subtitle">VDI - Удаленные рабочие места</h3>
        </Link>

        <Link className="services__item services__fourth" to="/business-solution">
          <h3 className="services__subtitle">РЕШЕНИЕ ДЛЯ БИЗНЕСА</h3>
        </Link>

        <Link className="services__item services__fifth" to="/audit">
          <h3 className="services__subtitle">АУДИТ</h3>
        </Link>

        <Link className="services__item services__sixth" to="/technical-visualization">
          <h3 className="services__subtitle">ТЕХНИЧЕСКАЯ ВИЗУАЛИЗАЦИЯ</h3>
        </Link>
      </div>
    </div>
  );
};

export default Services;
