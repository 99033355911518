import React from 'react';

import Header from '../components/Header';
import Layout from '../components/Layout';
import Title from '../components/Title';
import Description from '../components/Description';
import Block from '../components/Block';
import Text from '../components/Text';
import CalculateCostButton from '../components/CalculateCostButton';
import Footer from '../components/Footer';

import outstaffing from '../assets/services/outstaffing.png';
import Cookie from '../components/Cookie';

const Outstaffing = () => {
  window.scroll(0, 0);
  return (
    <>
      <Layout>
        <Header />
        <Block>
          <Title title="ИТ-АУТСТАФФИНГ" />
          <Description desc="Процесс подбора и найма квалифицированных специалистов может растянуться от 2 до 6 месяцев и более. Так же мы аккредитованная ИТ компания и пользуемся льготами от государства - в том числе более низкими ставками по налогам. Но что делать, если хороший сотрудник нужен срочно, а расширить штат - нет возможности?" />
          <img className="block__img" src={outstaffing} alt="ИТ-аутстаффинг" />
          <Text text="На помощь придет ИТ-аутстаффинг - быстрый способ закрыть недостающие компетенции для выполнения проектов в срок. Механизм прост: вы “арендуете” наших специалистов на определенное время, самостоятельно определяете их задачи. При этом вы экономите время и деньги на поиск специалиста. Как только потребность в таком специалисте пропадет, вы можете отказаться от услуг без финансовых потерь и бумажной волокиты." />
          <div className="paragraph-block">
            <p className="paragraph">Когда необходим аутстаффинг?</p>
            <p className="paragraph">Найм внештатного сотрудника нужен, если:</p>
            <p className="paragraph">
              • Вам нужны компетентные специалисты, но нет возможности создать дополнительные
              рабочие места
            </p>
            <p className="paragraph">
              • Вы создаете проект и не хотите загружать штатных специалистов этой задачей
            </p>
            <p className="paragraph">
              • В вашей компании нет специалистов определенной квалификации
            </p>
            <p className="paragraph">• Ваша компания ведет деятельность в другой стране</p>
            <p className="paragraph">
              Аутстаффинг упрощает работу команды над крупными проектами и снижает затраты на
              дополнительные рабочие места.
            </p>
          </div>
          <div className="paragraph-block">
            <p className="paragraph">Как происходит ИТ-аутстаффинг в нашей компании?</p>
            <p className="paragraph">
              Опыт работы с разными предприятиями позволяет нам сократить время подбора
              индивидуальных специалистов и команд к минимуму:
            </p>
            <p className="paragraph">
              1. Вы предоставляете нам информацию о проекте: направление, задачи, сроки и бюджет.
            </p>
            <p className="paragraph">
              2. Наш представитель связывается с вами для уточнения деталей и квалификации
              специалистов.
            </p>
            <p className="paragraph">
              3. Мы предоставляем вам резюме кандидатов, а вы их согласовываете на основе ваших
              предпочтений.
            </p>
          </div>
          <Text text="Простые 3 шага - и ваш проект укомплектован квалифицированными ИТ-специалистами, которые готовы сразу приступить к работе.Экономия от использования внештатных специалистов составит примерно 30-40%." />
          <CalculateCostButton name="Расcчитать стоимость" />
        </Block>
      </Layout>
      <Footer />
      <Cookie />
    </>
  );
};

export default Outstaffing;
