import React from 'react';

import Header from '../components/Header';
import Layout from '../components/Layout';
import Title from '../components/Title';
import Description from '../components/Description';
import Block from '../components/Block';
import CalculateCostButton from '../components/CalculateCostButton';
import Footer from '../components/Footer';

import vdi from '../assets/services/vdi.png';
import Cookie from '../components/Cookie';

const VirtualDesktopInfrastructure = () => {
  window.scroll(0, 0);
  return (
    <>
      <Layout>
        <Header />
        <Block>
          <Title title="VDI - Удаленные рабочие места" />
          <Description desc="Буквально 10 лет назад возможность полноценно работать из дома казалась неосуществимой. Но после пандемии 2020 года, “удаленка” - стала одним из самых распространенных способов организации рабочего процесса." />
          <img className="block__img" src={vdi} alt="Удаленные рабочие места" />
          <div className="paragraph-block">
            <p className="paragraph">
              Удаленные рабочие места позволяют обеспечить дистанционную работу сотрудников без
              качественных потерь и особого оборудования. Сотрудникам не нужно скачивать
              корпоративные приложения, все они могут хранится в облаке, к которому вы сами
              определяете доступ.
            </p>
            <p className="paragraph">Когда необходимы удаленные рабочие места?</p>
            <p className="paragraph">
              • Потребность перевести штат сотрудников в дистанционный формат
            </p>
            <p className="paragraph">
              • Краткосрочные проекты, которые требуют дополнительных сотрудников
            </p>
            <p className="paragraph">• Сокращение расходов на временные рабочие места</p>
            <p className="paragraph">• Стартапы с командами в разных городах, странах</p>
            <p className="paragraph">
              Мы создаем индивидуальные решения для каждого клиента в зависимости от масштаба
              организации и выполняемых задач.
            </p>
          </div>
          <div className="paragraph-block">
            <p className="paragraph">Преимущества удаленных рабочих мест:</p>
            <p className="paragraph">• Сокращение издержек</p>
            <p className="paragraph">
              Не нужно приобретать дополнительные ноутбуки, ПО, лицензии и другие элементы рабочего
              пространства.
            </p>
            <p className="paragraph">• Надежность данных</p>
            <p className="paragraph">
              Сотрудники компании имеют доступ только к ограниченным функциям и данным. Возможность
              нарушить их целостность практически отсутствует.
            </p>
            <p className="paragraph">• Простота использования для сотрудников</p>
            <p className="paragraph">
              Мы настраиваем облачные рабочие места на всех уровнях и выдаем краткие инструкции по
              запуску для вашего персонала. Вам не нужно будет заново обучать персонал и тратить
              время на налаживание всех процессов.
            </p>
            <p className="paragraph">• Возможность масштабирования</p>
            <p className="paragraph">
              Налаженная система удаленных рабочих мест может легко масштабироваться и
              подстраиваться под новые задачи компании.
            </p>
          </div>
          <div className="paragraph-block">
            <p className="paragraph">
              Как происходит создание удаленных рабочих мест в нашей компании?
            </p>
            <p className="paragraph">
              Для создания удаленных рабочих мест в вашей компании свяжитесь с нами.
            </p>
          </div>
          <CalculateCostButton name="Связаться с нами" />
        </Block>
      </Layout>
      <Footer />
      <Cookie />
    </>
  );
};

export default VirtualDesktopInfrastructure;
