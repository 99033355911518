import React from 'react';

import Header from '../components/Header';
import Layout from '../components/Layout';
import Title from '../components/Title';
import Description from '../components/Description';
import Block from '../components/Block';
import CalculateCostButton from '../components/CalculateCostButton';
import Footer from '../components/Footer';
import BusinessSolutionBlock from '../components/BusinessSolutionBlock';

import businessSolution from '../assets/services/businesssolution.png';
import Cookie from '../components/Cookie';

const BusinessSolution = () => {
  window.scroll(0, 0);
  return (
    <>
      <Layout>
        <Header />
        <Block>
          <Title title="РЕШЕНИЕ ДЛЯ БИЗНЕСА" />
          <Description desc="Трудно представить современную компанию без компьютеров с узконаправленным ПО, серверов, элементов сети, оргтехники, телефонии, облачных хранилищ и другого немаловажного для бизнеса оборудования." />
          <img className="block__img" src={businessSolution} alt="Решение для бизнеса" />
          <BusinessSolutionBlock />
          <CalculateCostButton name="Расcчитать стоимость" />
        </Block>
      </Layout>
      <Footer />
      <Cookie />
    </>
  );
};

export default BusinessSolution;
