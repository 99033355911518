import React from 'react';
import './map.css';

import Layout from '../Layout';

const Map = () => {
  return (
    <div className="map">
      <Layout>
        <div className="map__layout">
          <div className="map__content">
            <h2 className="map__title">Контакты</h2>
            <p className="map__text">г.Уфа улица Ленина 70, офис 68</p>
            <p className="map__text">+7 (347) 258‒82‒11</p>
            <p className="map__text">info@virtek.pro</p>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Map;
