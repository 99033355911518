import React from 'react';
import './details.css';

const Details = () => {
  return (
    <div className="details">
      <h2 className="title-block">Реквизиты</h2>
      <table class="iksweb">
        <tbody>
          <tr>
            <td>Полное наименование</td>
            <td className="uppercase">
              Общество с ограниченной ответственностью Научный Проектный Центр "ВирТЭК"
            </td>
          </tr>
          <tr>
            <td>Сокращенное наименование</td>
            <td>ООО НПЦ "ВИРТЭК"</td>
          </tr>
          <tr>
            <td>Юридический адрес</td>
            <td>Республика Башкортостан, г. Уфа, ул. Ленина, д.70, 5-й этаж офис 75</td>
          </tr>
          <tr>
            <td>Фактический адрес</td>
            <td>Республика Башкортостан, г. Уфа, ул. Ленина, д.70, 5-й этаж офис 68</td>
          </tr>
          <tr>
            <td>Директор</td>
            <td>Филипповский Евгений Александрович</td>
          </tr>
          <tr>
            <td>ИНН</td>
            <td>0274922889</td>
          </tr>
          <tr>
            <td>КПП</td>
            <td>027401001</td>
          </tr>
          <tr>
            <td>ОГРН</td>
            <td>1160280137820</td>
          </tr>
          <tr>
            <td>ОКВЭД</td>
            <td>
              62.03; 62.01; 62.02; 62.09; 61.10; 60.10; 61.10.1; 63.11; 82.99; 95.11; 46.52; 58.29;
              63.12; 77.33.1; 77.33.2; 95.11; 22.29; 26.11; 26.12; 26.20; 26.30; 26.51; 27.90;
              43.21; 46.66; 52.10; 71.12; 71.20; 72.11; 72.19
            </td>
          </tr>
          <tr>
            <td>Сайт предприятия</td>
            <td>https://www.virtek.pro/</td>
          </tr>
          <tr>
            <td>E-mail</td>
            <td>info@virtek.pro</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Details;
