import React from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Map from '../components/Map';
import Layout from '../components/Layout';
import AboutBlock from '../components/AboutBlock';
import AboutItems from '../components/AboutItems';
import Mission from '../components/Mission';
import WhyVirtek from '../components/WhyVirtek';
import Trust from '../components/Trust';
import Cookie from '../components/Cookie';

const About = () => {
  window.scroll(0, 0);
  return (
    <>
      <Layout>
        <Header />
      </Layout>
      <AboutBlock />
      <Layout>
        <AboutItems />
        <Mission />
      </Layout>
      <WhyVirtek />
      <Layout>
        <Trust />
      </Layout>
      <Map />
      <Footer />
      <Cookie />
    </>
  );
};

export default About;
