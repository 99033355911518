import React from 'react';
import './calculatecostbutton.css';

import Form from '../Form';

const CalculateCostButton = ({ name }) => {
  const [formActive, setFormActive] = React.useState(false);
  return (
    <>
      <button onClick={() => setFormActive(true)} className="calculatecostbutton">
        {name}
      </button>
      <Form active={formActive} setActive={setFormActive} />
    </>
  );
};

export default CalculateCostButton;
