import React from 'react';
import './items.css';

import itemOne from '../../assets/items/item-1.png';
import itemTwo from '../../assets/items/item-2.png';
import itemThree from '../../assets/items/item-3.png';
import itemFour from '../../assets/items/item-4.png';

const Items = () => {
  return (
    <div className="items">
      <div className="items__content">
        <div className="items__item">
          <img className="items__img" src={itemOne} alt="Изображение первого элемента" />
          <p className="items__text">Разработка программного обеспечения</p>
        </div>

        <div className="items__item">
          <img className="items__img" src={itemTwo} alt="Изображение второго элемента" />
          <p className="items__text">Автоматизация бизнес процессов</p>
        </div>

        <div className="items__item">
          <img className="items__img" src={itemThree} alt="Изображение третьего элемента" />
          <p className="items__text">Модернизация компьютеров</p>
        </div>

        <div className="items__item">
          <img className="items__img" src={itemFour} alt="Изображение четвертого элемента" />
          <p className="items__text">Бизнес под ключ</p>
        </div>
      </div>
    </div>
  );
};

export default Items;
