import React from 'react';
import './about.css';

import Layout from '../Layout';

import aboutPng from '../../assets/about/about-element.png';
import aboutTabletPng from '../../assets/about/about-element-tablet.png';
import aboutMobilePng from '../../assets/about/about-element-mobile.png';

const AboutBlock = () => {
  return (
    <div className="about">
      <Layout>
        <div className="about__layot">
          <h2 className="about__title">Рутина—машинам, людям—идеи</h2>
          <p className="about__text">
            Наша компания работает с использование российского ПО, такого как StaffCop , IT Invent,
            DIRECTUM, 1С:ERP, 1C: Бухгалтерия, 1C: ЗУП, включенных в реестр отечественного ПО.
          </p>
          <img className="about__img" src={aboutPng} alt="Декоративный элемент" />
          <img className="about__img-tablet" src={aboutTabletPng} alt="Декоративный элемент" />
          <img src={aboutMobilePng} alt="Декоративный элемент" className="about-img__mobile" />
        </div>
      </Layout>
    </div>
  );
};

export default AboutBlock;
