import React from 'react';
import { Routes, Route } from 'react-router-dom';

import './css/main.global.css';

import Home from './pages/Home';
import Outstaffing from './pages/Outstaffing';
import Outsourcing from './pages/Outsourcing';
import Audit from './pages/Audit';
import TechnicalVisualization from './pages/TechnicalVisualization';
import VirtualDesktopInfrastructure from './pages/VirtualDesktopInfrastructure';
import BusinessSolution from './pages/BusinessSolution';
import NotFound from './pages/NotFound';
import About from './pages/About';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/outstaffing" element={<Outstaffing />} />
        <Route path="/outsourcing" element={<Outsourcing />} />
        <Route path="/audit" element={<Audit />} />
        <Route path="/technical-visualization" element={<TechnicalVisualization />} />
        <Route path="/virtual-desktop-infrastructure" element={<VirtualDesktopInfrastructure />} />
        <Route path="/business-solution" element={<BusinessSolution />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
