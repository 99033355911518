import React from 'react';
import './header.css';

import Logo from './Logo';
import Menu from './Menu';
import ContactButton from './ContactButton';

const Header = () => {
  return (
    <header className="header">
      <Menu />
      <Logo />
      <ContactButton />
    </header>
  );
};

export default Header;
