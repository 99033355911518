import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Intro from '../components/Intro';
import Items from '../components/Items';
import Services from '../components/Services';
import WhyUs from '../components/WhyUs';
import Details from '../components/Details';
import Map from '../components/Map';
import Footer from '../components/Footer';
import Cookie from '../components/Cookie';

const Home = () => {
  window.scroll(0, 0);
  return (
    <>
      <Layout>
        <Header />
        <Intro />
        <Items />
        <Services />
        <WhyUs />
        <Details />
      </Layout>
      <Map />
      <Footer />
      <Cookie />
    </>
  );
};

export default Home;
