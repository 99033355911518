import React from 'react';

import Header from '../components/Header';
import Layout from '../components/Layout';
import Title from '../components/Title';
import Description from '../components/Description';
import Block from '../components/Block';
import Text from '../components/Text';
import CalculateCostButton from '../components/CalculateCostButton';
import Footer from '../components/Footer';

import outsourcing from '../assets/services/outsourcing.png';
import Cookie from '../components/Cookie';

const Outsourcing = () => {
  window.scroll(0, 0);
  return (
    <>
      <Layout>
        <Header />
        <Block>
          <Title title="ИТ-Аутсорсинг" />
          <Description desc="Трудно представить современную компанию без компьютеров с узконаправленным ПО, серверов, элементов сети, оргтехники, телефонии, облачных хранилищ и другого немаловажного для бизнеса оборудования." />
          <img className="block__img" src={outsourcing} alt="ИТ-аутсорсингг" />
          <Text text="Для их комплексного технического обслуживания требуются значительные затраты: финансовые и трудовые. Поиск квалифицированных специалистов и покупка оборудования могут оттянуть запуск компании или проекта на неопределенный период. ИТ-аутсорсинг позволяет избежать этих проблем и оптимизировать ресурсы предприятия, кратно увеличить прибыль за счет передачи непрофильных функций внешним исполнителям. Вам не нужно будет нанимать штатных специалистов, обустраивать их рабочие места, оформлять трудовой договор, выдавать больничные листы и платить аренду за дополнительную площадь. При этом вы получите гарантированно качественный результат и работу в срок – без горящих дедлайнов и отложенных задач." />
          <div className="paragraph-block">
            <p className="paragraph">Как понять, что вашей компании нужен аутсорсинг в сфере ИТ?</p>
            <p className="paragraph">
              Привлечение внешних исполнителей необходимо, если бизнесу нужно:
            </p>
            <p className="paragraph">• Сократить общие расходы на обслуживание аппаратуры</p>
            <p className="paragraph">• Уделить больше внимания основному направлению</p>
            <p className="paragraph">• Повысить качество работы и конечного продукта</p>
            <p className="paragraph">• Выполнять задачи в незнакомых ранее сферах</p>
            <p className="paragraph">• Решить проблемы с производительностью</p>
            <p className="paragraph">
              ИТ-аутсорсинг позволит вам сосредоточиться на том, что действительно важно для
              компании – на стратегических задачах.
            </p>
          </div>
          <div className="paragraph-block">
            <p className="paragraph">Как происходит ИТ-аутсорсинг в нашей компании?</p>
            <p className="paragraph">
              Наши специалисты снимают всю лишнюю техническую нагрузку с клиентов: вам не нужно
              переживать о стоимости диагностики оборудования в случае неисправности или о
              непредвиденных сбоях.
            </p>
          </div>
          <div className="paragraph-block">
            <p className="paragraph">Мы предлагаем:</p>
            <p className="paragraph">
              • Комплексное техническое обслуживание оборудования (серверов, компьютеров, ноутбуков
              )
            </p>
            <p className="paragraph">• Дистанционную поддержку пользователей</p>
            <p className="paragraph">• Мониторинг ИТ-инфраструктуры для предотвращения сбоев</p>
            <p className="paragraph">• Организация непрерывной работы ИТ-сервисов</p>
            <p className="paragraph">• Обеспечение сохранности данных компании</p>
            <p className="paragraph">
              Качественный ИТ-аутсорсинг обеспечит вам снижение рисков при сбоях ИТ-инфраструктуры и
              высокий уровень сервиса. Контроль за техническим обслуживанием позволит предоставлять
              качественные услуги.
            </p>
            <p className="paragraph">
              Для расчета стоимости ИТ-аутсорсинга для вашей компании свяжитесь с нами.
            </p>
          </div>
          <CalculateCostButton name="Расcчитать стоимость" />
        </Block>
      </Layout>
      <Footer />
      <Cookie />
    </>
  );
};

export default Outsourcing;
