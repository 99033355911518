import React from 'react';

import Header from '../components/Header';
import Layout from '../components/Layout';
import Title from '../components/Title';
import Description from '../components/Description';
import Block from '../components/Block';
import Text from '../components/Text';
import CalculateCostButton from '../components/CalculateCostButton';
import Footer from '../components/Footer';

import audit from '../assets/services/audit.png';
import Cookie from '../components/Cookie';

const Audit = () => {
  window.scroll(0, 0);
  return (
    <>
      <Layout>
        <Header />
        <Block>
          <Title title="АУДИТ БЕЗОПАСНОСТИ СУЩЕСТВУЮЩИХ СИСТЕМ" />
          <Description desc="Информация сейчас играет особую роль в бизнесе - без нее невозможно представить ни одну компанию. Ее потеря, утечка данных или целенаправленные атаки злоумышленников могут сильно навредить работе предприятия, его репутации и принести крупные финансовые потери. Мы рекомендуем регулярно проводить аудит безопасности существующей системы." />
          <img className="block__img" src={audit} alt="Аудит безопасности существующих систем" />
          <Text text="Тщательно тестируем инструменты каждого бизнес-процесса, чтобы обеспечить полную защиту вашего предприятия и предотвратить несанкционированный доступ." />
          <div className="paragraph-block">
            <p className="paragraph">Какие услуги предоставляем?</p>
            <p className="paragraph">Инструментальный аудит</p>
            <p className="paragraph">
              Подразумевает использование программных (и реже аппаратных) комплексов сканирования на
              предмет выявления уязвимостей. Типичными примерами таких средств могут быть Positive
              xSpider, MaxPatrol, Nessus, Aqunetics и другие. Такой инструмент дает общую картину о
              защищенности сети компании, а в многостраничном отчете сканера безопасности может быть
              много ложных срабатываний.
            </p>
          </div>
          <div className="paragraph-block">
            <p className="paragraph">Ручной аудит</p>
            <p className="paragraph">
              Тестирование проходит по методу "белого", "серого" или "черного ящика" - определяется
              количеством информации, которое специалист знает до начала тестирования. В рамках
              теста создаются эксплойты, чтобы анализировать уязвимости с нуля. Такой аудит
              заканчивается детальным отчетом об успешных результатах взлома и путях потенциального
              хакера, которым тот может воспользоваться для проникновения.
            </p>
          </div>
          <div className="paragraph-block">
            <p className="paragraph">Автоматический аудит</p>
            <p className="paragraph">
              Применяем подход на основе автоматизации, но целью является выявление конкретных
              возможных векторов проникновения нарушителей в вашу сеть. Объединяем простой вариант
              сканера уязвимостей с тестом на проникновение. В этом случае в инфраструктуре
              устанавливается специализированное решение, созданное нашими сотрудниками.
            </p>
          </div>
          <Text text="Проведение аудита безопасности не только убережет вашу компанию от материальных потерь, но и сохранит конфиденциальные данные, уникальные разработки от злоумышленников." />
          <CalculateCostButton name="Расcчитать стоимость" />
        </Block>
      </Layout>
      <Footer />
      <Cookie />
    </>
  );
};

export default Audit;
