import React from 'react';
import './mission.css';

import missionPng from '../../assets/mission/mission.png';

const Mission = () => {
  return (
    <div className="mission">
      <img className="mission__img" src={missionPng} alt="Изображение в разделе миссия" />
      <div className="mission__content">
        <h2 className="about-main-subtitle mission__title">Миссия</h2>
        <img className="mission__img-mobile" src={missionPng} alt="Изображение в разделе миссия" />
        <p className="mission__text">
          Миссия нашей компании&nbsp;&mdash; обеспечить автоматизацию бизнеса и&nbsp;освободить
          больше ресурсов компании-заказчика, предоставить конкурентные преимущества бизнесу
          со&nbsp;стороны ИТ. Мы&nbsp;займемся всей операционкой, а&nbsp;вы&nbsp;сможете
          сфокусироваться на&nbsp;том, что действительно важно для роста и&nbsp;развития.
        </p>
      </div>
    </div>
  );
};

export default Mission;
