import React from 'react';

import Header from '../components/Header';
import Layout from '../components/Layout';
import Title from '../components/Title';
import Block from '../components/Block';
import Text from '../components/Text';
import CalculateCostButton from '../components/CalculateCostButton';
import Footer from '../components/Footer';

import technicalVisualization from '../assets/services/technicalvisualization.png';
import Cookie from '../components/Cookie';

const TechnicalVisualization = () => {
  window.scroll(0, 0);
  return (
    <>
      <Layout>
        <Header />
        <Block>
          <Title title="ТЕХНИЧЕСКАЯ ВИЗУАЛИЗАЦИЯ" />
          <img className="block__img" src={technicalVisualization} alt="Техническая визуализация" />
          <Text text="На помощь придет ИТ-аутстаффинг - быстрый способ закрыть недостающие компетенции для выполнения проектов в срок. Механизм прост: вы “арендуете” наших специалистов на определенное время, самостоятельно определяете их задачи. При этом вы экономите время и деньги на поиск специалиста. Как только потребность в таком специалисте пропадет, вы можете отказаться от услуг без финансовых потерь и бумажной волокиты." />
          <div className="paragraph-block">
            <p className="paragraph">Помимо этого, техническая визуализация позволяет:</p>
            <p className="paragraph">
              • Реализовать самые разные проекты и предоставить простую и понятную визуализацию
            </p>
            <p className="paragraph">• Сформировать у клиента полное представление о продукте</p>
            <p className="paragraph">
              • Представить, как будет выглядеть товар (объект) при выпуске на рынок
            </p>
            <p className="paragraph">
              В ООО НПЦ “ВирТЭК” мы предлагаем клиентам разработку 3D-моделей с нуля и доработку уже
              готовых работ. Вам не нужно переживать о покупке специального ПО для разработки и
              поиска специалиста - все это уже есть в нашей компании.
            </p>
            <p className="paragraph">
              Основным инструментом для реализации данной услуги в нашей компании выступает
              Компас-3D - программный комплекс компании Аскон, который позволяет реализовать
              огромное количество вариантов инженерных решений и беспрепятственно внести изменения в
              уже существующие разработки.
            </p>
            <p className="paragraph">
              Вы также можете согласовать разработку в других программных средствах - для этого
              просто свяжитесь с нами.
            </p>
          </div>
          <CalculateCostButton name="Связаться с нами" />
        </Block>
      </Layout>
      <Footer />
      <Cookie />
    </>
  );
};

export default TechnicalVisualization;
