import React from 'react';
import './whyvirtek.css';

import Layout from '../Layout';
import Form from '../Form';

const WhyVirtek = () => {
  const [formActive, setFormActive] = React.useState(false);
  return (
    <>
      <div className="why-virtek">
        <Layout>
          <h2 className="about-main-subtitle why-virtek__title">Почему Virtek?</h2>
        </Layout>
        <div className="why-virtek__content">
          <Layout>
            <div className="why-virtek__items">
              <div className="why-virtek__item why-virtek__item-first">
                <p className="why-virtek__text-main">
                  Для каждой компании подбираем отдельную стратегию работы, удовлетворяющую
                  индивидуальным запросам и потребностям
                </p>
                <button className="why-virtek__button why-virtek__button-mobile-top">
                  Связаться с нами
                </button>
              </div>
              <div className="why-virtek__item why-virtek__item-second">
                <p className="why-virtek__subtitle">Автоматизация бизнес-процессов</p>
                <p className="why-virtek__text">
                  CRM-системы, IP-телефония и информационная безопасность.
                </p>
              </div>
              <div className="why-virtek__item why-virtek__item-third">
                <p className="why-virtek__subtitle">Решения для бизнеса</p>
                <p className="why-virtek__text">
                  Ремонт компьютеров, поставка цифровой техники, импортозамещение, чат-боты и
                  API-модули.
                </p>
              </div>
              <div className="why-virtek__item why-virtek__item-fourth">
                <p className="why-virtek__subtitle">IT-аутсорсинг</p>
                <p className="why-virtek__text">
                  Поддержка серверов, 1С-сопровождение, техническая поддержка пользователей, монтаж
                  ЛВС, видеонаблюдение и СКУД.
                </p>
              </div>
              <div className="why-virtek__item why-virtek__item-fifth">
                <p className="why-virtek__subtitle">Бизнес под ключ</p>
                <p className="why-virtek__text">
                  Электронная коммерция, мобильные приложения, корпоративные порталы,
                  интернет-магазины, отраслевая разработка и дизайн
                </p>
              </div>
              <div className="why-virtek__item why-virtek__item-sixth">
                <p className="why-virtek__subtitle">Модернизация</p>
                <p className="why-virtek__text">
                  Серверы и системы хранения данных, ЦОД, виртуализация рабочих мест, инженерные
                  системы и облачный офис.
                </p>
                <button
                  className="why-virtek__button why-virtek__button-mobile-bottom"
                  onClick={() => setFormActive(true)}>
                  Связаться с нами
                </button>
              </div>
            </div>
          </Layout>
        </div>
      </div>
      <Form active={formActive} setActive={setFormActive} />
    </>
  );
};

export default WhyVirtek;
