import React from 'react';
import './contactbutton.css';

import Form from '../../Form';

const ContactButton = () => {
  const [formActive, setFormActive] = React.useState(false);

  return (
    <>
      <button onClick={() => setFormActive(true)} className="contact__button">
        Связаться с нами
      </button>
      <Form active={formActive} setActive={setFormActive} />
    </>
  );
};

export default ContactButton;
