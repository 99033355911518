import React from 'react';
import './form.css';

import { Link } from 'react-router-dom';

import politika from '../../assets/doc/politika.pdf';

const Form = ({ active, setActive }) => {
  return (
    <div className={active ? 'form' : 'form-not-active'} onClick={() => setActive(false)}>
      <form className="form-content" action="" onClick={(el) => el.stopPropagation()}>
        <h2 className="form-title">Оставьте контактные данные и мы свяжемся с Вами</h2>
        <input className="form-input" id="name" type="text" placeholder="ФИО*" />
        <input className="form-input" id="phone" type="text" placeholder="Телефон*" />
        <input className="form-input" id="email" type="text" placeholder="E-mail" />
        <input className="form-input" id="text" type="text" placeholder="Сooбщение" />
        <div className="form-checkbox">
          <input type="checkbox" id="checkbox" />
          <label htmlFor="checkbox">
            Соглашаюсь с{' '}
            <a className="checkbox__politika" href={politika}>
              политикой{' '}
            </a>{' '}
            в области обработки персональных данных
          </label>
        </div>
        <input id="button" className="form-btn" type="submit" value="Отправить" />
        <button className="form-btn-close" onClick={() => setActive(false)}>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_214_846)">
              <path
                d="M31.6667 10.682L29.3167 8.33203L20 17.6487L10.6833 8.33203L8.33334 10.682L17.65 19.9987L8.33334 29.3154L10.6833 31.6654L20 22.3487L29.3167 31.6654L31.6667 29.3154L22.35 19.9987L31.6667 10.682Z"
                fill="#21242E"
                fill-opacity="0.4"
              />
            </g>
            <defs>
              <clipPath id="clip0_214_846">
                <rect width="40" height="40" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </form>
    </div>
  );
};

export default Form;
