import React from 'react';
import './intro.css';

import { Link } from 'react-router-dom';

import introImg from '../../assets/intro/intro.png';

const Intro = () => {
  return (
    <div className="intro">
      <div className="intro__img-content">
        <img className="intro__img" src={introImg} alt="Изображение на главном экране" />
        <div className="intro__name">
          <p className="intro__title">Innovation-Transformation</p>
          <p className="intro__text">Качественные услуги для вашего бизнеса</p>
          <Link className="intro__button" to="/about">
            О компании
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Intro;
