import React from 'react';
import './logo.css';

import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <Link className="logo" to="/">
      <svg
        width="300"
        height="84"
        viewBox="0 0 300 84"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_197_1363)">
          <path d="M189.66 20.6562H138.226V31.6725H189.66V20.6562Z" fill="white" />
          <path d="M79.4458 20.6562H68.4244V83.9996H79.4458V20.6562Z" fill="white" />
          <path d="M168.994 34.4238H157.973V83.9968H168.994V34.4238Z" fill="white" />
          <path d="M240.633 20.6562H198.844V31.6725H240.633V20.6562Z" fill="white" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M209.866 34.4238H198.844V72.9801V83.9963V83.9968H209.866V83.9963H240.633V72.9801H209.866V58.2914H240.633V47.2753H209.866V34.4238Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M59.2397 20.6562H46.3817L26.0763 68.2931L31.7871 83.8687L59.2397 20.6562Z"
            fill="#FE701E"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 0L31.7543 48.1133L28.1181 55.901L24.4821 63.6891L0 0Z"
            fill="#FE701E"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M260.839 20.6562H249.818V83.9996H260.839V20.6562ZM263.594 45.4444V52.7878V52.7887V60.1319L284.949 84.0006H299.873L271.861 52.7883L292.067 20.6578H279.667L263.594 45.4444Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M120.013 61.3602L119.747 61.0603L138.141 83.8043L126.034 83.8814L108.348 61.8446H99.6518V83.9996H88.6305L88.7218 54.438L88.6353 33.9676H99.1925V51.6948H105.977H112.532C118.963 51.6948 123.547 48.3236 123.547 41.3467C123.547 34.369 118.963 30.9845 112.532 30.9845H88.6353L88.6305 20.6564L115.954 20.6562C126.463 20.6562 134.726 29.7006 134.726 41.1374C134.726 51.0394 128.548 59.3173 120.013 61.3602Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_197_1363">
            <rect width="300" height="84" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Link>
  );
};

export default Logo;
